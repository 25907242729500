<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <configuration class="" @reloadRiskCharts="reloadCharts"></configuration>
      <seguimiento class="ml-5" @reloadRiskCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Riesgo</h3>
    <!-- RESUMEN -->
    <div class="mx-auto" max-width="auto">
      <div class="resumen">
        <div class="resumen-detail">
          <div class="tway-violet--text mb-6">
            <span style="font-size: 18px">Resumen</span>
          </div>
          <div class="resumen-detail-item" v-for="(resume, index) in resumeRisk" :key="index">
            <div>{{ resume.description }}:</div>
            <div class="resumen-detail-item-amount">
              <div>{{ resume.value }}</div>
              <div>({{ resume.percentage }}%)</div>
            </div>
          </div>
        </div>
        <div class="resumen-bar">
          <VueApexCharts
            height="150"
            :series="summaryRisk.series"
            :options="summaryRisk.options"
            type="bar"
            class="full"
          />
        </div>
      </div>
    </div>
    <!-- MATRIZ DE RIESGO & MATRIZ CATEGORIAS-->
    <div class="graphics-container-duo">
      <div>
        <!-- Matriz Riesgo -->
        <div>
          <div class="tway-violet--text">
            <p>
              Matriz de Riesgo
              <ToolTips width="300" description="Matriz de Probabilidad de Ocurrencia vs Impacto de un Riesgo." />
            </p>
          </div>
          <div>
            <SimpleBubbleChart
              v-if="riskMatriz.series.length > 0"
              :series="riskMatriz.series"
              :options="riskMatriz.options"
            />
          </div>
        </div>
        <!-- Matriz Categorias -->
        <div>
          <div>
            <div class="tway-violet--text">
              <p>
                Riesgo por Categoría
                <ToolTips
                  width="300px"
                  description="Indicador que agrupa Riesgos con Mayor Probabilidad de Ocurrencia e Impacto, por Categoría."
                />
              </p>
            </div>
          </div>
          <div>
            <PolarArea
              v-if="riskByDimension.series.length > 0"
              :series="riskByDimension.series"
              :options="riskByDimension.options"
              :labels="riskByDimension.options.labels"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="box-table">
      <div class="box-table-title tway-violet--text">
        Lista de Riesgos
        <!-- <div v-if="messages.isAccomplishmentTableEmpty">
          <v-icon color="purple"> mdi-content-save </v-icon>
        </div> -->
      </div>

      <table class="table-data-risk">
        <tr>
          <th colspan="2"></th>
          <th v-for="(header, index) in headers" :key="index">
            {{ header.text }}
          </th>
        </tr>
        <template v-for="(risk, index) in topRisk.list">
          <tr :key="index + '-1'">
            <td>
              <div class="classification-color" :style="'background-color:' + risk.risk_classification"></div>
            </td>
            <td>{{ risk.num }}</td>
            <td>{{ risk.priority }}</td>
            <td>{{ risk.risk }}</td>
            <td>{{ risk.stateName }}</td>
            <td>{{ risk.category }}</td>
            <td>{{ risk.strategyName }}</td>
            <td>{{ risk.responsible }}</td>
            <td>{{ risk.critical_date }}</td>
            <td>
              <v-btn
                class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text btn-detail"
                color="coral lighten-2"
                outlined
                v-bind="attrs"
                v-on="on"
                @click="selectIndex(index)"
              >
                <v-icon class="tway-violet--text"> mdi-details </v-icon>
              </v-btn>
            </td>
          </tr>
          <template v-if="index === indexSelect">
            <tr :key="index + '-2'">
              <td colspan="5" style="text-align: left">
                <b>Ocurrencia:</b> {{ risk.currency }} <b>Impacto:</b> {{ risk.impact }} <b>Fecha de Registro:</b>
                {{ risk.registration_date }} <b>Fecha de Cierre:</b>
                {{ risk.closing_date }}
              </td>
              <td colspan="5" style="text-align: left"></td>
            </tr>
            <tr :key="index + '-3'">
              <td colspan="10" style="text-align: left"><b>Descripción:</b> {{ risk.description }}</td>
            </tr>
            <tr :key="index + '-4'">
              <td colspan="10" style="text-align: left" class="last-td"><b>Plan:</b> {{ risk.plan }}</td>
            </tr>
          </template>
          <template v-else>
            <tr :key="index + '-5'">
              <td colspan="10" class="last-td-empty"></td>
            </tr>
          </template>
        </template>
        <!--
      <tr>
        <th v-for="(risk, index) in topRisk.list" :key="index">
          {{ risk.text }}
        </th>
      </tr>
      --></table>
    </div>
    <!--
    <v-data-table :headers="headers" :items="topRisk.list" class="elevation-2">
      <template v-slot:item.risk_classification="{ item }">
        <v-chip :color="item.risk_classification" dark></v-chip>
      </template>
    </v-data-table>
    -->
  </div>
</template>

<script>
import Configuration from "../../components/pmo/risk/configuration.vue";
import Seguimiento from "../../components/pmo/risk/seguimiento.vue";
import RiskService from "@/services/pmo/risk/riskService";
// import StackedBars100 from "../../components/charts/pmo/apex/bar/StackedBars100";
import SimpleBubbleChart from "../../components/charts/pmo/apex/bubble/SimpleBubbleChart";
import PolarArea from "../../components/charts/pmo/apex/polar-area/PolarArea";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "Risk",
  components: {
    Configuration,
    Seguimiento,
    // StackedBars100,
    SimpleBubbleChart,
    PolarArea,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      indexSelect: null,
      headers: [
        { text: "Prioridad", value: "priority" },
        { text: "Riesgo", value: "risk" },
        { text: "Estado", value: "state" },
        { text: "Categoría", value: "category" },
        // { text: "Ocurrencia", value: "currency" },
        // { text: "Impacto", value: "impact" },
        { text: "Estrategia", value: "strategy" },
        // { text: "Descripción", value: "description" },
        // { text: "Plan", value: "plan" },
        { text: "Nombre Responsable", value: "responsible" },
        // { text: "Fecha Registro", value: "registration_date" },
        { text: "Fecha Crítica", value: "critical_date" },
        //{ text: "Fecha Cierre", value: "closing_date" },
        { text: "Detalle", value: "critical_date" },
      ],
      top10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      charts: {
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      serieStacked: [],
      riskMatriz: {
        series: [],
        options: {
          chart: {
            height: 300,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bubble",
            events: { mouseOver: undefined },
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
              type: "xy",
              autoScaleYaxis: false,
            },
          },
          dataLabels: { enabled: false },
          fill: { opacity: 0.8 },
          title: { text: undefined },
          xaxis: {
            title: { text: "Impacto" },
            tickAmount: 6,
            min: 0,
            max: 6,
          },
          yaxis: {
            title: { text: "Probabiildad Ocurrencia" },
            tickAmount: 6,
            min: 0,
            max: 6,
          },
          crosshairs: { show: true, width: 30 },
          legend: {
            show: true,
            position: "bottom",
            offsetX: 0,
            offsetY: 7,
          },
          labels: ["Insignificante", "Menor", "Moderado", "Mayor", "Catastrófico"],
          colors: [
            "#7F7F7F",
            "#4A148C",
            "#16C62E",
            "#AEEA00",
            "#AEEA00",
            "#AEEA00",
            "#AEEA00",
            "#7319D5",
            "#7319D5",
            "#7319D5",
          ],
          tooltip: {
            z: {
              title: "Prioridad:",
              formatter: function (val) {
                if (val < 6) {
                  return "Baja";
                } else if (val > 6 && val < 15) {
                  return "Media";
                } else if (val >= 15) {
                  return "Alta";
                }
              },
            },
          },
        },
      },
      max: 0,
      summaryRisk: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 180,
            width: 1250,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          stroke: {
            width: 0,
            colors: ["#fff"],
            curve: "smooth",
            lineCap: "round",
            dashArray: 0,
          },
          /*yaxis: {
            title: {
              text: undefined,
            },
          },*/
          colors: ["#D7263D", "#4A148C", "#16C62E", "#AEEA00", "#7319D5"],
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "left",
            horizontalAlign: "left",
            offsetX: 0,
            offsetY: 35,
          },
        },
      },
      largo: 0,
      riskByDimension: {
        series: [],
        options: {
          labels: [],
          legend: {
            position: "bottom",
          },
          chart: {
            height: 400,
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "polarArea",
            zoom: { enabled: false },
          },
          stroke: {
            colors: ["#fff"],
          },
          fill: {
            opacity: 0.8,
          },
          yaxis: {
            max: 0,
          },
          // Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Verde Claro:'#16C62E' - Gris: '#7F7F7F'
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      topRisk: [],
      resumeRisk: [],
    };
  },
  methods: {
    reloadCharts() {
      this.callService();
    },
    maxFunc() {
      return (this.riskByDimension.options.yaxis.max = this.max);
    },
    selectIndex(index) {
      this.indexSelect = index === this.indexSelect ? null : index;
    },
    async callService() {
      await RiskService.getSummaryRisk(this.project)
        .then((data) => {
          this.summaryRisk = data;
          this.resumeRisk = data.resume;
        })
        .catch((err) => console.dir(err));

      await RiskService.getRiskMatriz(this.project)
        .then((data) => {
          this.riskMatriz.series = data.series;
        })
        .catch((err) => console.dir(err));

      await RiskService.getRiskByDimension(this.project)
        .then((data) => {
          this.riskByDimension.options.labels = data.labels;
          this.riskByDimension.series = data.series;
          this.max = Math.max(data.series[0]) < 5 ? 5 : Math.max(data.series[0]) + 2;
          this.riskByDimension.options.yaxis.max = this.max;
        })
        .catch((err) => console.dir(err));

      await RiskService.getTopRisk(this.project)
        .then((data) => {
          this.topRisk = data;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}

.resumen {
  display: flex;
  width: 100%;
  height: 235px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: space-around;
  margin: 25px 0px 5px 0px;
  border-radius: 20px;
}

.resumen > .resumen-detail {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin: 30px 11px;
  font-size: 14px !important;
}
.resumen > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-detail > .resumen-detail-item > .resumen-detail-item-amount {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.resumen-detail-item-amount > div:first-child {
  text-align: right;
  width: 65%;
}

.resumen > .resumen-bar {
  display: flex;
  width: 75%;
  height: fit-content;
  margin: 55px 10px 0px 0px;
}
.resumen-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
}
.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.seguimiento {
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: center;
  margin: 25px 0px 25px 0px;
}
.seguimiento-periodo {
  width: 60%;
  margin: 2px;
}
.seguimiento-costo {
  width: 38%;
  margin: 2px;
}
.list-top-10 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  margin-top: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin: 25px 0px 25px 0px;
}
.list-top-10 > .list-top-10-title {
  font-size: 25px;
}
.list-top-10 > .list-top-10-subtitle {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 15px;
  justify-content: space-between;
  margin: 0px 10px 10px 10px;
  border-bottom: 1px solid black;
  text-align: center;
}
.list-top-10 > .list-top-10-content {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
}
.risk_classification,
.num,
.priority,
.risk,
.state,
.category,
.currency,
.impact,
.strategy,
.description,
.plan,
.responsible,
.registration_date,
.critical_date,
.closing_date,
.year,
.month {
  border: 1px solid black;
  font-size: 10px !important;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.risk_classification {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin: 0px 5px 0px 5px;
}
.num {
  width: 2%;
}
.priority {
  width: 2%;
}
.risk {
  width: 15%;
}
.state {
  width: 5%;
}
.category {
  width: 5%;
}
.currency {
  width: 5%;
}
.impact {
  width: 5%;
}
.strategy {
  width: 5%;
}
.description {
  width: 15%;
}
.plan {
  width: 15%;
}
.responsible {
  width: 5%;
}
.registration_date {
  width: 5%;
}
.critical_date {
  width: 5%;
}
.closing_date {
  width: 5%;
}
.year {
  width: 5%;
}
.mont {
  width: 5%;
}
table.v-table tbody td {
  font-size: 11px !important;
}
table tbody tr td {
  font-size: 10px !important;
}
.v-data-table__wrapper > table {
  font-size: 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
.v-application > .text-start {
  text-align: start !important;
  font-size: 10px !important;
}
.v-data-table::v-deep th {
  font-size: 10px !important;
}
.v-data-table::v-deep td {
  font-size: 10px !important;
}

.table-data-risk {
  font-size: 12px !important;
  text-align: center;
  border-radius: 4px;
  border: 0.5px solid rgb(150, 146, 146);
  width: 98%;
  margin: 20px 5px 10px 5px;
  border-collapse: collapse;
  th {
    border: 0.5px solid rgb(150, 146, 146);
    height: 30px;
  }
  td {
    //border: 1px solid black;
    height: 30px;
    padding-left: 5px;
    .classification-color {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      border: 1px;
    }
  }
  .last-td {
    border-bottom: 1px solid rgb(150, 146, 146);
    padding-bottom: 15px;
  }
  .last-td-empty {
    border-bottom: 1px solid rgb(150, 146, 146);
    padding-bottom: 15px;
    height: auto;
  }
}
.full {
  width: 100%;
  height: fit-content;
}
.btn-detail {
  width: 15px !important;
  height: 25px !important;
  min-width: 15px !important;
  margin: 0px auto;
}
.box-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px !important;
  justify-content: center;
  margin: 25px 0px 25px 0px;
  padding: 25px 20px 30px 20px;
  font-size: 13px;
}
.box-table-title {
  display: flex;
  font-size: 17px;
  margin-bottom: 15px;
}
.graphics-container-duo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px 25px 20px;
  margin: 25px 0px 25px 0px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  border-radius: 20px !important;
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 47%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
</style>
