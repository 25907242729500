<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>

      <v-card :class="'modalSystem'">
        <v-btn icon right @click="dialog = false" class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card>
          <h3 class="text-center">Seguimiento de riesgo</h3>
          <h4 class="text-center font-weight-regular">Ingresa nuevo Seguimiento</h4>
          <div class="container formSeg">
            <v-row
              :v-if="items.length > 0"
              v-for="(item, index) in items"
              :key="index"
              :class="{ hide: hidden }"
              class="mt-5 formRow"
            >
              <v-col class="col-2">
                <v-text-field
                  v-model="item.riesgo"
                  label="Riesgo"
                  maxlength="15"
                  minlenght="0"
                  :counter="15"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.currency"
                  item-value="id"
                  item-text="name"
                  label="Ocurrencia"
                  v-model="item.ocurrencia"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.impact"
                  item-value="id"
                  item-text="name"
                  label="Impacto"
                  v-model="item.impacto"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.state"
                  item-value="id"
                  item-text="name"
                  label="Estado"
                  v-model="item.estado"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.categories"
                  item-value="id"
                  item-text="name"
                  label="Categoría"
                  v-model="item.categoria"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.resposible"
                  item-value="id"
                  item-text="name"
                  label="Responsable"
                  v-model="item.responsable"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-2">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :min="date"
                  color="indigo lighten-2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaCritica"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="indigo lighten-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fechaCritica" color="indigo lighten-2" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="col-2">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :min="date"
                  color="indigo lighten-2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaCierre"
                      label="Fecha Cierre"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :min="date"
                      color="indigo lighten-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaCierre"
                    :min="date"
                    @input="menu3 = false"
                    color="indigo lighten-2"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="col-2">
                <v-select
                  :items="combos.strategy"
                  item-value="id"
                  item-text="name"
                  label="Estrategia"
                  v-model="item.estrategia"
                  outlined
                ></v-select>
              </v-col>
              <v-col class="col-6">
                <v-text-field
                  v-model="item.descripcion"
                  label="Descripción"
                  maxlength="65"
                  minlenght="0"
                  :counter="15"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="col-10">
                <v-text-field
                  v-model="item.plan"
                  label="Plan"
                  maxlength="120"
                  minlenght="0"
                  :counter="50"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="justify-center col-2">
                <div class="btn d-flex justify-center mt-3 buttons">
                  <v-btn
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                    outlined
                    color="indigo lighten-2"
                    @click="addRiskTrace()"
                  >
                    <v-icon class="tway-violet--text">mdi-plus</v-icon>
                    Agregar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-5"></v-divider>
          <div class="container">
            <h4 class="text-center font-weight-regular">Listado de Seguimiento Riesgo</h4>
            <v-simple-table class="table mt-2" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" scope="col">ID</th>
                    <th class="text-center" scope="col">Riesgo</th>
                    <th class="text-center" scope="col">Ocurrencia</th>
                    <th class="text-center" scope="col">Estado</th>
                    <th class="text-center" scope="col">Categoría</th>
                    <th class="text-center" scope="col">Estrategia</th>
                    <th class="text-center" scope="col">Responsable</th>
                    <th class="text-center" scope="col">Fecha Crítica</th>
                    <th class="text-center" width="150" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <template :v-if="items.length > 0" v-for="(item, index) in riskItems">
                    <tr
                      :key="index"
                      :class="item.isVisible ? hidden : activeClass"
                      class="edit_row"
                      style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
                    >
                      <td class="text-center" width="50">{{ item.index }}</td>
                      <td class="text-center" width="100">
                        <v-text-field
                          v-model="item.name"
                          maxlength="15"
                          minlenght="0"
                          :counter="15"
                          outlined
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-select
                          :items="item.Currency"
                          item-value="id"
                          item-text="name"
                          v-model="item.currencyId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" width="100">
                        <v-select
                          :items="item.State"
                          item-value="id"
                          item-text="name"
                          v-model="item.stateId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" width="100">
                        <v-select
                          :items="item.categories"
                          item-value="id"
                          item-text="name"
                          v-model="item.categoryId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" width="100">
                        <v-select
                          :items="item.Strategy"
                          item-value="id"
                          item-text="name"
                          v-model="item.strategyId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" width="150">
                        <v-select
                          :items="item.responsible"
                          item-value="id"
                          item-text="name"
                          v-model="item.responsibleId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" width="110">
                        <v-menu
                          ref="menu"
                          v-model="item.menu"
                          :close-on-content-click="true"
                          :return-value.sync="date"
                          transition="scale-transition"
                          :nudge-right="40"
                          offset-y
                          min-width="auto"
                          :min="date"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="item.criticalDate"
                              prepend-icon="mdi-calendar"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="item.criticalDate" @input="item.menu = false"></v-date-picker>
                        </v-menu>
                      </td>
                      <td class="text-center buttons">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="primary" class="edit" @click="saveItem(item)" v-bind="attrs" v-on="on">
                              <v-icon>edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="primary"
                              class="save"
                              @click="updateRiskTrace(item)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>save</v-icon>
                            </v-btn>
                          </template>
                          <span>Guardar</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteRiskTrace(item.identificador)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="green" v-bind="attrs" v-on="on" @click="isShow = !isShow">
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>Ver detalles</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr
                      :class="item.isVisible ? activeClass : hidden"
                      :key="index"
                      class="edit_row"
                      style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
                      v-show="!isShow"
                    >
                      <th class="text-center">Impacto</th>
                      <th class="text-center" colspan="2">Fecha Cierre</th>
                      <th class="text-center" colspan="4">Descripción</th>
                      <th class="text-center" colspan="2">Plan</th>
                    </tr>
                    <tr
                      :class="item.isVisible ? hidden : activeClass"
                      :key="index"
                      class="edit_row"
                      style="border-bottom: thin solid rgba(0, 0, 0, 0.12)"
                      v-show="!isShow"
                    >
                      <td class="text-center">
                        <v-select
                          :items="item.Impact"
                          item-value="id"
                          item-text="name"
                          v-model="item.impactId"
                          outlined
                        ></v-select>
                      </td>
                      <td class="text-center" colspan="2">
                        <v-menu
                          ref="menu"
                          v-model="item.menu1"
                          :close-on-content-click="true"
                          :return-value.sync="date"
                          transition="scale-transition"
                          :nudge-right="40"
                          offset-y
                          min-width="auto"
                          :min="date"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="item.closingDate"
                              prepend-icon="mdi-calendar"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="item.closingDate" @input="item.menu1 = false"></v-date-picker>
                        </v-menu>
                      </td>
                      <td class="text-center" colspan="4">
                        <v-text-field
                          v-model="item.description"
                          maxlength="50"
                          minlenght="0"
                          :counter="15"
                          outlined
                        ></v-text-field>
                      </td>
                      <td class="text-center" colspan="2">
                        <v-text-field
                          v-model="item.plan"
                          maxlength="50"
                          minlenght="0"
                          :counter="15"
                          outlined
                        ></v-text-field>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
            <v-row>
              <v-col md="12" class="justify-center">
                <div class="btn d-flex justify-center mt-6">
                  <v-btn
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text justify-center"
                    outlined
                    color="indigo lighten-2"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                    Guardar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import riskService from "../../../services/pmo/risk/riskService";

export default {
  data: () => ({
    dialog: false,
    ocurrencia: ["1", "2", "3", "4", "5", "6"],
    impacto: ["Insignificante", "Menor", "Moderado", "Mayor", "Catastrófico"],
    estado: ["Levantado", "Gestionado", "Pendiente", "Atrasado"],
    categoria: ["Proceso", "Ambiente", "Materiales", "Equipo"],
    responsable: ["Andrea", "Francisco", "Aldo", "César"],
    estrategia: ["Mitigar", "Transferir", "Escalar"],
    items: [
      {
        ocurrencia: "",
        impacto: "",
        estado: "",
        categoria: "",
        riesgo: "",
        responsable: "",
        estrategia: "",
        descripcion: "",
        plan: "",
      },
    ],
    riskItems: [],
    combos: {
      currency: [],
      impact: [],
      state: [],
      strategy: [],
      resposible: [],
      categories: [],
    },
    maxIndex: 0,
    nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    fechaCritica: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    menu3: false,
    isVisible: false,
    activeClass: "is-visible",
    isShow: true,
  }),
  methods: {
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadRiskCharts");
    },
    clearForm() {
      this.items = [
        {
          ocurrencia: "",
          impacto: "",
          estado: "",
          categoria: "",
          riesgo: "",
          responsable: "",
          estrategia: "",
          descripcion: "",
          plan: "",
        },
      ];
    },
    AddItem() {
      this.items.push({
        anio: this.anio,
        mes: this.mes,
        costo: this.costo,
        total: this.total,
        monto: this.monto,
      });
    },
    removeItem() {
      if (this.items.length > 1) {
        this.items.splice(this.items, 1);
      }
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    getAllRisk() {
      riskService
        .getAllRisk(this.project)
        .then((data) => {
          this.riskItems = data.items;
          this.combos.currency = data.general.Currency;
          this.combos.impact = data.general.Impact;
          this.combos.state = data.general.State;
          this.combos.strategy = data.general.Strategy;
          this.combos.resposible = data.general.responsible;
          this.combos.categories = data.general.categories;
          this.maxIndex = data.general.maxIndex;
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveUnit = false;
          setTimeout(() => (this.alert.saveUnit = false), 4000);
        });
    },
    addRiskTrace() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: this.items[0].riesgo,
        state: this.items[0].estado,
        idCategory: this.items[0].categoria,
        strategy: this.items[0].estrategia,
        idResponsible: this.items[0].responsable,
        criticalDate: this.fechaCritica,
        occurrence: this.items[0].ocurrencia,
        impact: this.items[0].impacto,
        closingDate: this.fechaCierre,
        description: this.items[0].descripcion,
        plan: this.items[0].plan,
      };

      riskService
        .addRiskTrace(request)
        .then(() => {
          this.getAllRisk();
          this.clearForm();
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alertBox.on = false), 4000);
          this.clearForm();
        });
    },
    updateRiskTrace(item) {
      item.isVisible = !item.isVisible;
      const request = {
        idRisk: item.identificador,
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        name: item.name,
        state: item.stateId,
        idCategory: item.categoryId,
        strategy: item.strategyId,
        idResponsible: item.responsibleId,
        criticalDate: item.criticalDate,
        occurrence: item.currencyId,
        impact: item.impactId,
        closingDate: item.closingDate,
        description: item.description,
        plan: item.plan,
      };
      riskService
        .addRiskTrace(request)
        .then(() => {
          this.getAllRisk();
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    deleteRiskTrace(idRisk) {
      riskService
        .deleteRiskTrace(idRisk)
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          riskService
            .getAllRisk(this.project)
            .then((data) => {
              this.riskItems = data.items;
            })
            .catch((err) => console.dir(err));

          setTimeout(() => (this.alertBox.on = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getAllRisk();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}
.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}
.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}
.v-text-field__details {
  display: none;
}
.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}
.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}
tr.edit_row td {
  height: 60px !important;
}
.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}
.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}
.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}
tr.edit_row td .edit {
  display: none;
}
tr.edit_row.is-visible td {
  pointer-events: none;
}
tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}
tr.edit_row.is-visible td .save {
  display: none !important;
}
tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}
tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}
tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}
.formSeg .formRow {
  display: none;
}
.formSeg .formRow:last-child {
  display: flex;
}
.edit_row
  .v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined
  .v-input__prepend-outer {
  display: none;
}
.v-data-table__wrapper .edit_row th.text-center {
  color: rgba(0, 0, 0, 0.6);
}
td.text-center {
  padding: 0 10px !important;
}
div.v-input__slot {
  padding: 0 8px !important;
}
</style>
