<template>
  <v-row justify="center">
    <v-dialog v-model="turn" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          {{ message }}
        </v-card-title>
        <v-card-text>{{ messageBody }}.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="off(false)"> Cancelar </v-btn>
          <v-btn color="green darken-1" text @click="off(true)"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    message: {
      type: String,
      required: true,
    },
    messageBody: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      turn: false,
    };
  },
  methods: {
    open() {
      this.turn = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    off(val) {
      if (val) {
        this.resolve(true);
        this.turn = false;
      } else {
        this.reject(false);
        this.turn = false;
      }
    },
  },
};
</script>
