<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="900">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="tway-violet--text"> mdi-cog-outline </v-icon>
          CONFIGURACIÓN
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-btn icon right @click="dialog = false" class="float-right">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card>
          <div class="container">
            <h3 class="text-center">Configuración de riesgos</h3>
            <h4 class="text-center font-weight-regular">Categorías de Riesgos</h4>
            <form>
              <div class="d-flex justify-space-between mt-2">
                <v-row class="mt-6">
                  <v-col md="6">
                    <v-text-field
                      label="Nombre Categoría de Riesgo 1"
                      v-model="categories.category1"
                      :hide-details="true"
                      :maxlength="'15'"
                      :minlenght="'0'"
                      type="text"
                      outlined
                      required
                    />
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Nombre Categoría de Riesgo 2"
                      v-model="categories.category2"
                      :hide-details="true"
                      :maxlength="'15'"
                      :minlenght="'0'"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Nombre Categoría de Riesgo 3"
                      v-model="categories.category3"
                      :hide-details="true"
                      :maxlength="'15'"
                      :minlenght="'0'"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Nombre Categoría de Riesgo 4"
                      v-model="categories.category4"
                      :hide-details="true"
                      :maxlength="'15'"
                      :minlenght="'0'"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      label="Nombre Categoría de Riesgo 5"
                      v-model="categories.category5"
                      :hide-details="true"
                      :maxlength="'15'"
                      :minlenght="'0'"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="12" class="text-center d-flex justify-center pa-md-10 mx-lg-auto">
                    <v-btn
                      class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                      outlined
                      color="indigo lighten-2"
                      @click="
                        addCategories();
                        closeConfigurationDialog();
                      "
                    >
                      <v-icon class="tway-violet--text"> mdi-content-save </v-icon>
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </form>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
    <confirmation ref="confirm" :message="dialogMessage.title" :message-body="dialogMessage.body"></confirmation>
  </div>
</template>

<script>
import Confirmation from "../utils/ConfirmationDialog.vue";
import RiskService from "@/services/pmo/risk/riskService";

export default {
  components: { Confirmation },
  data: (vm) => {
    return {
      on: false,
      dialogMessage: {
        title: "",
        body: "",
      },
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
      ),
      loading: {
        saveStage: false,
        saveActivity: false,
      },
      alert: {
        saveStage: false,
        saveActivity: false,
      },
      messageError: {
        saveStage: "",
        saveActivity: "",
      },
      activity: {
        stageSelected: 0,
        name: "",
        dateStart: "",
        dateEnd: "",
        responsableSelected: 0,
      },
      stage: {
        name: "",
        dateStart: "",
        dateEnd: "",
      },
      dialog: false,
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      startDateProposal: "",
      endDateProposal: "",
      etapas: [],
      combos: {
        selectEtapas: [],
        responsible: [],
      },
      setup: null,
      activities: [],
      categories: {
        category1: "",
        category2: "",
        category3: "",
        category4: "",
        category5: "",
      },
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    sortedList: function () {
      return this.allScores.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    sortedList2: function () {
      return this.allScor.slice().sort(function (a, b) {
        return b.score - a.score;
      });
    },
    computedStartDateProposal() {
      return this.formatDate(this.setup.startDateProposal);
    },
    computedStageDateStart() {
      return this.formatDate(this.stage.dateStart);
    },
    computedStageDateEnd() {
      return this.formatDate(this.stage.dateEnd);
    },
    computedDateFormatted5() {
      return this.formatDate(this.activity.dateStart);
    },
    computedDateFormatted6() {
      return this.formatDate(this.activity.dateEnd);
    },
    computedEndDateProposal(val) {
      return val;
    },
  },
  methods: {
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadRiskCharts");
      this.addCategories();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    clearForm() {},
    addCategories() {
      const request = {
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
        category1: this.categories.category1,
        category2: this.categories.category2,
        category3: this.categories.category3,
        category4: this.categories.category4,
        category5: this.categories.category5,
      };
      RiskService.addCategories(request)
        .then(() => this.clearForm())
        .catch((err) => {
          console.dir(err);
          this.getCategories();
        })
        .finally(() => {
          this.loading.buttonSaveTrack = false;
          setTimeout(() => (this.alertBox.on = false), 4000);
          this.clearForm();
        });
    },
    getCategories() {
      RiskService.getCategories(this.project)
        .then((data) => {
          this.categories.category1 = data.data[0].name;
          this.categories.category2 = data.data[1].name;
          this.categories.category3 = data.data[2].name;
          this.categories.category4 = data.data[3].name;
          this.categories.category5 = data.data[4].name;
          console.log("categorias: ", data.data[0].name);
          console.log("categorias: ", data.data[1].name);
          console.log("categorias: ", data.data[2].name);
          console.log("categorias: ", data.data[3].name);
        })
        .catch((err) => {
          console.dir(err);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.getCategories();
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
</style>
